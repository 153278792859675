import { defineComponent } from 'vue';
import GreenArrows from '../../assets/svg/green-arrows.svg';
import BaseButton from '../atoms/BaseButton.vue';
export default defineComponent({
    name: 'JobCheckTeaserSquare',
    components: { BaseButton, GreenArrows },
    props: { imgCorner: String, jobCheckUrl: String },
    setup() {
        return { GreenArrows };
    },
});
